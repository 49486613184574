import React from 'react'
import * as PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

const galleryProptypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  images: PropTypes.array,
}

const Gallery = props => {
  const { title, description, images } = props
  const desc = description ? JSON.parse(description) : ''

  return (
    <div className="bg-white text-neutral-700">
      {title && (
        <h2
          className="mt-0 py-[30px] px-0 text-center text-[2rem] font-normal before:py-0 before:px-[10px] before:content-['-'] after:py-0 after:px-[10px] after:content-['-']"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {description && (
        <p
          className="my-0 mx-auto w-[80%] pb-[14px] text-center text-[1.4rem]"
          dangerouslySetInnerHTML={{ __html: desc.content[0].content[0].value }}
        />
      )}

      {images &&
        images.map(image => (
          <GatsbyImage
            imgClassName="block w-full"
            image={image.localFile.childImageSharp.gatsbyImageData}
            key={image.id}
            alt={image.description}
          />
        ))}
    </div>
  )
}

Gallery.propTypes = galleryProptypes

export default Gallery
