import React from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import { css } from '@emotion/react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CoverImage from '../components/coverImage'
import Overview from '../components/overview'
import Slideshow from '../components/slideshow'
import Gallery from '../components/gallery'

import UpArrow from '../images/up_arrow.svg'

const propTypes = {
  data: PropTypes.object.isRequired,
}

const demoStyles = css`
  background-color: hsl(192, 5%, 82%);
  padding: 80px 0;
  position: relative;
  text-align: center;

  p {
    margin: 0;

    ~ p {
      margin-top: 1rem;
    }
  }
`

const demoProptypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  linkType: PropTypes.string,
}

const demoLinkStyles = css`
  border-bottom: 3px solid #333;
  color: #333;
  font-size: 1.8rem;
  text-decoration: none;
  text-transform: uppercase;
`

const jumpLinkStyles = css`
  background-color: hsl(0, 0%, 20%);
  border-radius: 50%;
  display: block;
  left: calc(50% - 24px);
  padding: 10px;
  position: absolute;
  top: -24px;

  img {
    display: block;
    height: 32px;
    width: 32px;
  }
`

const Demo = props => {
  const { url, title, linkType } = props
  return (
    <p>
      <a href={url} css={demoLinkStyles}>
        View {title} {linkType}
      </a>
    </p>
  )
}

Demo.propTypes = demoProptypes

const ProjectTemplate = props => {
  const project = props.data.contentfulProject
  const { projectDetails } = project

  return (
    <Layout unstyled>
      <SEO title={project.title} />
      <section className="project">
        {projectDetails &&
          projectDetails.map(contentType => {
            if (contentType.internal.type === 'ContentfulProjectCoverImage') {
              return <CoverImage key={contentType.id} image={contentType.coverImage} />
            } else if (contentType.internal.type === 'ContentfulProjectOverview') {
              return <Overview key={contentType.id} description={contentType.description && contentType.description.description} />
            } else if (contentType.internal.type === 'ContentfulProjectSlideshow') {
              return (
                <Slideshow
                  key={contentType.id}
                  title={contentType.title}
                  description={contentType.description && contentType.description.description}
                  images={contentType.slideshow}
                />
              )
            } else if (contentType.internal.type === 'ContentfulProjectGallery') {
              return (
                <Gallery
                  key={contentType.id}
                  title={contentType.title}
                  description={contentType.description && contentType.description.description}
                  images={contentType.gallery}
                />
              )
            }
          })}
      </section>

      <section css={demoStyles}>
        <a href="#" css={jumpLinkStyles}>
          <img src={UpArrow} alt="Jump to the top" />
        </a>
        {projectDetails &&
          projectDetails.map(contentType => {
            if (contentType.internal.type === 'ContentfulProjectDemo') {
              return <Demo key={contentType.id} url={contentType.url} title={contentType.title} linkType={contentType.linkType} />
            }
          })}
      </section>
    </Layout>
  )
}

ProjectTemplate.propTypes = propTypes

export default ProjectTemplate

export const pageQuery = graphql`
  query($id: String!) {
    contentfulProject(id: { eq: $id }) {
      id
      title
      description {
        description
      }
      projectDetails {
        ... on ContentfulProjectCoverImage {
          id
          internal {
            type
          }
          coverImage {
            description
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        ... on ContentfulProjectOverview {
          id
          internal {
            type
          }
          description {
            description
          }
        }
        ... on ContentfulProjectSlideshow {
          id
          internal {
            type
          }
          title
          description {
            raw
          }
          slideshow {
            id
            description
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            file {
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        ... on ContentfulProjectGallery {
          id
          internal {
            type
          }
          title
          description {
            raw
          }
          gallery {
            id
            title
            description
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        ... on ContentfulProjectDemo {
          id
          internal {
            type
          }
          url
          title
          linkType
        }
      }
    }
  }
`
