import React from 'react'
import * as PropTypes from 'prop-types'

const overviewProptypes = {
  description: PropTypes.string,
}

const Overview = props => {
  const { description } = props
  return (
    <div className="bg-white py-[30px] px-0 text-neutral-700">
      <h2 className="mt-0 py-[30px] px-0 text-center text-[2rem] font-normal before:py-0 before:px-[10px] before:content-['-'] after:py-0 after:px-[10px] after:content-['-']">
        Project Overview
      </h2>
      <p
        className="mx-auto my-0 w-[80%] pb-[14px] text-center text-[1.4rem]"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </div>
  )
}

Overview.propTypes = overviewProptypes

export default Overview
