import React from 'react'
import * as PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

const coverImageProptypes = {
  image: PropTypes.object,
}

const CoverImage = props => {
  const { image } = props
  return (
    <div className="block w-full">
      <GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} alt={image.description} />
    </div>
  )
}

CoverImage.propTypes = coverImageProptypes

export default CoverImage
