import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import { PhotoSwipe } from 'react-photoswipe'

const slideshowProptypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  images: PropTypes.array,
}

const Slideshow = ({ title, description, images }) => {
  const desc = description ? JSON.parse(description) : ''
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const imageMetadata = images.map(
    ({
      description,
      file: {
        details: {
          image: { height, width },
        },
      },
      localFile: { publicURL },
    }) => ({
      src: publicURL,
      w: width,
      h: height,
      title: description,
    }),
  )

  // Photoswipe Settings
  const [isOpen, setIsOpen] = useState(false)
  const [index, setIndex] = useState(null)
  const options = {
    history: false,
    index: index || 0,
  }

  const openPhotoSwipe = (e, i) => {
    e.preventDefault()
    setIsOpen(true)
    setIndex(i)
  }

  const handleClose = () => {
    setIsOpen(false)
    setIndex(null)
  }

  return (
    <div className="overflow-hidden bg-white text-neutral-700">
      {title && (
        <h2
          dangerouslySetInnerHTML={{ __html: title }}
          className="mt-0 py-[30px] px-0 text-center text-[2rem] font-normal before:py-0 before:px-[10px] before:content-['-'] after:py-0 after:px-[10px] after:content-['-']"
        />
      )}
      {description && (
        <p
          dangerouslySetInnerHTML={{ __html: desc.content[0].content[0].value }}
          className="my-0 mx-auto w-[80%] pb-[14px] text-center text-[1.4rem]"
        />
      )}

      <Slider {...settings}>
        {images &&
          images.map((image, i) => (
            <div key={image.id}>
              <a href="#" onClick={e => openPhotoSwipe(e, i)}>
                <GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} alt={image.description} />
              </a>
            </div>
          ))}
      </Slider>
      <PhotoSwipe isOpen={isOpen} items={imageMetadata} options={options} onClose={handleClose} />
    </div>
  )
}

Slideshow.propTypes = slideshowProptypes

export default Slideshow
